exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-equipa-js": () => import("./../../../src/pages/equipa.js" /* webpackChunkName: "component---src-pages-equipa-js" */),
  "component---src-pages-especializacoes-js": () => import("./../../../src/pages/especializacoes.js" /* webpackChunkName: "component---src-pages-especializacoes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-templates-equipa-js": () => import("./../../../src/templates/equipa.js" /* webpackChunkName: "component---src-templates-equipa-js" */),
  "component---src-templates-especializacoes-js": () => import("./../../../src/templates/especializacoes.js" /* webpackChunkName: "component---src-templates-especializacoes-js" */)
}

